import { useUserInfoStore } from "~/stores/userInfoStore";

const exemptSections = [
    "build",
    "asset",
    "industry",
]

export const checkNodeAvailable = ( node: any, instance?: string) => {
    const userInfo = useUserInfoStore().info as any

    console.log('checkNodeAvailable userInfo', userInfo)
    console.log('checkNodeAvailable node', node)

    if (node.name == '行业'){
        return true
    }
    if (node.path.match(/^内容文件\/4.行业/)){
        return true
    }
    if (node.path.match(/项目/)){
        return true
    }
    if((userInfo.user_type == "华美员工")
    ||(userInfo.user_company == "华美顾问")){
        if (node.name == '华美'){
            return true
        }
        if (node.path.match(/^内容文件\/7.华美/)){
            return true
        }
    }else{
        if (node.name == '华美'){
            return false
        }
        if (node.path.match(/^内容文件\/7.华美/)){
            return false
        }
    }

    if(node.path.match(/^\/startPage\/city/)){
        if ((!userInfo[`city_range_allow_all`]) && 
            (!userInfo[`available_cities`].includes(node.queries['instance']))){
            return false
        }
    }else if(node.path.match(/^\/startPage\/brand/)){
        if ((!userInfo[`brand_range_allow_all`]) && 
            (!userInfo[`available_brands`].includes(node.queries['instance']))){
            return false
        }
    }else if(node.path.match(/^城市/)){
        if ((!userInfo[`city_range_allow_all`]) && 
            (!userInfo[`available_cities`].includes(instance))){
            return false
        }
    }else if(node.path.match(/^品牌/)){
        if ((!userInfo[`brand_range_allow_all`]) && 
            (!userInfo[`available_brands`].includes(instance))){
            return false
        }
    }else if(node.path.match(/^酒店/)){
        if ((!userInfo[`hotel_range_allow_all`]) && 
            (!userInfo[`available_hotels`].includes(instance))){
            return false
        }
    }else if(node.path.match(/^集团/)){
        if ((!userInfo[`group_range_allow_all`]) && 
            (!userInfo[`available_groups`].includes(instance))){
            return false
        }
    }else if (node.path == "我的"){
        return true
    }else{
        for (const path of userInfo[`available_paths`].filter(Boolean)){
            console.log('checkNodeAvailable path', node.path, path, node.path.includes(path))
            if (node.path.includes(path)){
                return true
            }
        }
        return false
    }
    return true
}
